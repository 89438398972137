import React, { Component } from "react";
import { connect } from "react-redux";
import bbox from "@turf/bbox";

import get_url_query from "../../App/validation/get_url_query";

import icon_aset from "../../Assets/img/dashboard.png";

const layout_point = {
  "icon-image": "icon_1",
  "icon-size": 1, // Icon size
  "icon-allow-overlap": true, // Allow icons to overlap
};

const geojson_point = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      properties: {
        status: "a",
      },
      geometry: {
        coordinates: [100.13562096944713, 0.29651491059931345],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        status: "a",
      },
      geometry: {
        coordinates: [104.31599799280133, -4.198195094746765],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        status: "a",
      },
      geometry: {
        coordinates: [110.08153209735406, -7.471425540433202],
        type: "Point",
      },
    },
    {
      type: "Feature",
      properties: {
        status: "a",
      },
      geometry: {
        coordinates: [112.94483608722027, -0.9572128629112342],
        type: "Point",
      },
    },
  ],
};

class LIBRE_MACHRUS extends Component {
  state = {};

  componentDidMount() {
    const mode = get_url_query("mode");
    if (mode === "machrus") {
      this.on_render_content();
      this.on_fly();
      const { map_object } = this.props.layer;
      if (map_object) {
        map_object.on("load", async () => {
          await this.loadIcons(); // Load icons once the map is ready
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.layer.map_object !== prevProps.layer.map_object ||
      this.props.properties.basemap_used !== prevProps.properties.basemap_used
    ) {
      const mode = get_url_query("mode");
      if (mode === "machrus") {
        this.on_render_content();
        this.on_fly();
        const { map_object } = this.props.layer;
        if (map_object) {
          map_object.on("load", async () => {
            await this.loadIcons(); // Load icons once the map is ready
          });
        }
      }
    }
  }

  // Function to load icons asynchronously
  loadImageAsync(url) {
    const { map_object } = this.props.layer;

    return new Promise((resolve, reject) => {
      map_object.loadImage(url, (error, image) => {
        if (error) reject(error);
        resolve(image);
      });
    });
  }

  // Async function to handle loading multiple icons
  async loadIcons() {
    const { map_object } = this.props.layer;

    if (!map_object) return; // Make sure map_object exists

    try {
      // Load multiple images asynchronously
      const icon_1 = await this.loadImageAsync(icon_aset);

      // const icon_1 = await this.loadImageAsync(
      //   "https://mapidstorage.s3.ap-southeast-1.amazonaws.com/general_image/machrus/1702886926114_pp_big_1702886925_db667be2-e474-4ed8-8b0b-a63ffe2286b8.png"
      // );

      // Add the images to the map
      await map_object.addImage("icon_1", icon_1);
    } catch (error) {
      console.error("Error loading icons:", error);
    }
  }

  on_render_content = () => {
    const { map_object } = this.props.layer;
    if (map_object !== null) {
      if (!map_object.isStyleLoaded()) {
        // If the style is not yet loaded, wait until it is
        map_object.once("styledata", () => {
          this.addMapContent(map_object);
        });
      } else {
        // If the style is already loaded, proceed immediately
        this.addMapContent(map_object);
      }
    }
  };

  // Helper function to handle the logic for adding the map content
  addMapContent = (map_object) => {
    const id_map_point = "libre_machrus_point";

    if (!map_object.getSource(id_map_point)) {
      map_object.addSource(id_map_point, {
        type: "geojson",
        data: geojson_point,
      });
    } else {
      map_object.getSource(id_map_point).setData(geojson_point);
    }
    if (!map_object.getLayer(id_map_point)) {
      map_object.addLayer({
        id: id_map_point,
        source: id_map_point,
        type: "symbol",
        layout: layout_point,
      });
    } else {
      for (const key in layout_point) {
        map_object.setLayoutProperty(id_map_point, key, layout_point[key]);
      }
    }
  };

  on_fly = () => {
    const { map_object } = this.props.layer;

    if (map_object) {
      const { sidebar_right_status, sidebar_left_status } =
        this.props.properties;

      let top = 50;
      let bottom = 400;
      let left = 10;
      let right = 10;

      if (window.innerWidth < 1172) {
        //MOBILE
        top = 50;
        bottom = 400;
        left = 10;
        right = 10;
      } else {
        //DESKTOP
        top = 150;
        bottom = 300;
        left = 420;
        right = 500;
        if (sidebar_right_status === true) {
          right = 500;
        } else {
          right = 50;
        }
        if (sidebar_left_status === true) {
          left = 500;
        } else {
          left = 50;
        }
      }
      const padding = { top, bottom, left, right };
      const [min_longitude, min_latitude, max_longitude, max_latitude] =
        bbox(geojson_point);
      map_object.fitBounds(
        [
          [min_longitude, min_latitude],
          [max_longitude, max_latitude],
        ],
        {
          padding,
          maxZoom: this.props.layer.max_zoom,
          duration: 750,
        }
      );
    }
  };

  render() {
    return <main />;
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, {})(LIBRE_MACHRUS);
