/**
 * generates popup content using DOM elements.
 * @param {Object} properties - the properties to display in the popup.
 * @param {Array} fields - the field definitions for content display.
 */

/*
DONE:

text
image
textarea (full screen belum)
number
phone_number
currency
year
date
document

TO DO:
link



NOT DONE:

time
selection
radio_button
checklist
link
auto_address
auto_address_poi
table
calculate_dimension
*/

import format_date_formal from "../../App/validation/format_date_formal";

export const generatePopupContentDOM = (properties, fields, type_2) => {
  fields = fields.filter((field) => !!field?.isStyle === false);
  console.log(type_2);
  // Create main container
  const container = document.createElement("main");
  container.id = `popup_${properties?.key}`;

  // Create wrapper for close button
  const header_wrapper = document.createElement("div");
  header_wrapper.style.position = "sticky";
  header_wrapper.style.top = "0";
  header_wrapper.style.background = "white";
  header_wrapper.style.width = "100%"; // Ensure full width
  header_wrapper.style.display = "flex"; // Use flexbox
  header_wrapper.style.justifyContent = "space-between"; // Space out buttons
  header_wrapper.style.alignItems = "center"; // Vertically center items
  header_wrapper.style.padding = "0"; // Add some padding for aesthetics

  // Create and add full button
  const full_button = document.createElement("button");
  full_button.className = "button button_white outline_black text_left";
  full_button.textContent = "Perbesar";
  header_wrapper.appendChild(full_button);

  // Create and add close button
  const close_button = document.createElement("button");
  close_button.className = "button background_black text_right";
  close_button.textContent = "X";
  header_wrapper.appendChild(close_button);

  // Append header_wrapper to container
  container.appendChild(header_wrapper);

  //create scrollable section for popup content
  const content_section = document.createElement("section");
  content_section.style.width = "200px";
  content_section.style.maxWidth = "80vw";
  content_section.style.maxHeight = "200px";
  content_section.style.overflowY = "auto";
  content_section.style.overflowX = "hidden";
  let banjir_button;
  if (type_2 === "banjir") {
    banjir_button = document.createElement("button");
    banjir_button.className = "button button_white outline_black text_left";
    banjir_button.textContent = "Generate Genangan";
    content_section.appendChild(banjir_button);
  }
  //generate dynamic content inside the content section
  fields.forEach((field) => {
    let value = properties?.[field?.key];

    let field_name_div, value_div;

    field_name_div = document.createElement("div");
    field_name_div.className = "text_small";
    field_name_div.textContent = field?.name;
    content_section.appendChild(field_name_div);
    content_section.appendChild(document.createElement("br"));

    switch (field.type) {
      case "text":
        value_div = document.createElement("div");
        value_div.className = "text_bold";
        value_div.textContent = value || "-";
        content_section.appendChild(value_div);
        content_section.appendChild(document.createElement("br"));
        break;

      case "number":
        value_div = document.createElement("div");
        value_div.className = "text_bold";
        if (!isNaN(value))
          value = new Intl.NumberFormat("id-ID", { style: "decimal" }).format(
            value
          );
        value_div.textContent = value || "-";
        content_section.appendChild(value_div);
        content_section.appendChild(document.createElement("br"));
        break;

      case "currency":
        value_div = document.createElement("div");
        value_div.className = "text_bold";
        if (!isNaN(value))
          value = new Intl.NumberFormat("id-ID", { style: "decimal" }).format(
            value
          );
        value_div.textContent = value || "-";
        content_section.appendChild(value_div);
        content_section.appendChild(document.createElement("br"));
        break;

      case "date":
        value_div = document.createElement("div");
        value_div.className = "text_bold";
        value = format_date_formal(value);
        value_div.textContent = value || "-";
        content_section.appendChild(value_div);
        content_section.appendChild(document.createElement("br"));
        break;

      case "image":
        value_div = document.createElement("div");
        let img = document.createElement("img");
        img.src = value;
        img.alt = field.name || "Image";
        img.className = "photo_popup";
        value_div.appendChild(img);
        content_section.appendChild(value_div);
        content_section.appendChild(document.createElement("br"));
        break;

      case "document":
        value_div = document.createElement("a");
        value_div.href = value;
        value_div.target = "_blank";
        value_div.ref = "noopener noreferrer";
        value_div.className =
          "button_small button_white outline_black text_left";
        value_div.textContent = "Download doc";
        content_section.appendChild(value_div);
        content_section.appendChild(document.createElement("br"));
        content_section.appendChild(document.createElement("br"));
        break;

      case "link":
        let href;
        if (!value?.includes("https://") && !value?.includes("http://")) {
          href = `https://${value}`; // Tambahkan https jika URL tidak valid.
        }
        value_div = document.createElement("a");
        value_div.href = href;
        value_div.target = "_blank";
        value_div.ref = "noopener noreferrer";
        value_div.className =
          "button_small button_white outline_black text_left";
        value_div.textContent = `View link to ${value}`;
        content_section.appendChild(value_div);
        content_section.appendChild(document.createElement("br"));
        content_section.appendChild(document.createElement("br"));
        break;

      case "textarea":
        value_div = document.createElement("div");
        value_div.textContent = value || "-";
        value_div.style.maxHeight = "100px"; // Set max height
        value_div.style.overflowY = "hidden"; // Enable vertical scrolling
        value_div.style.border = "1px solid #ccc"; // Optional: add border for textarea-like appearance
        value_div.style.padding = "8px"; // Optional: padding for aesthetics
        value_div.style.whiteSpace = "pre-wrap"; // Preserve whitespace like a textarea
        content_section.appendChild(value_div);
        const full_button = document.createElement("button");
        full_button.className =
          "button_small button_white outline_black text_left";
        full_button.textContent = "Perbesar";
        content_section.appendChild(full_button);
        content_section.appendChild(document.createElement("br"));
        content_section.appendChild(document.createElement("br"));
        break;

      default:
        value_div = document.createElement("div");
        value_div.className = "text_bold";
        value_div.textContent = value || "-";
        content_section.appendChild(value_div);
        content_section.appendChild(document.createElement("br"));
    }
  });

  //append the close button and content section to the main container
  container.appendChild(header_wrapper); //close button at the top
  container.appendChild(content_section); //scrollable content section below

  return { container, close_button, full_button, banjir_button };
};
